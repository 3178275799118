import React from "react"
import Layout from "../components/Layout"
import {StaticImage} from 'gatsby-plugin-image'
import Seo from '../components/Seo'

export default function Home() {
  return (
  <Layout>
    <Seo  title="Home" />
   <main className="page">
     <header className="hero">
       <StaticImage src="../assets/images/mainphoto.jpg" alt="people and cloud"
       className="hero-img" placeholder="tracedSVG"
       layout="fullWidth">
       </StaticImage>
       <div className="hero-container">
       <div className="hero-text">
         <h1>your cloud</h1>
         <h4>your dream, your future</h4>
       </div>
       </div>
     </header>
     <section className="about-page">
       <article>
       <h5>Welkom bij CloudBrainz</h5> 
       <p>
       Cloudbrainz is een full  service cloud consultancy bureau met focus op 
       Amazon WebServices (AWS). 
      </p>
      <p>
      Wij zijn een team van AWS gecertificeerde specialisten 
       die AWS optimaal inzetten om jouw business doelen te realiseren.
      </p>
       </article>
       <article>
       <h5>Neem ons mee</h5> 
       <p>
        Wij horen graag over jouw project, start-up idee, ambitie of uitdaging.
        Geheel vrijblijvend gaan we samen onderzoeken hoe de inzet van Amazon WebServices (AWS) jouw bedrijf hierbij kan helpen.
      Zie je de samenwerking zitten, dan gaan we graag voor jouw organisatie aan de slag. 
      </p>
       </article>
     </section>
   </main>
  </Layout>
  )
}
